// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bv_k5";
export var caseStudyBackground__lineColor = "bv_kX";
export var caseStudyHead = "bv_k7";
export var caseStudyHead__imageWrapper = "bv_kV";
export var caseStudyProjectsSection = "bv_k6";
export var caseStudyQuote__bgRing = "bv_k2";
export var caseStudyQuote__bottomVideo = "bv_lK";
export var caseStudyVideoReview = "bv_lV";
export var caseStudyVideoReview__bgRing = "bv_lW";
export var caseStudyVideo__ring = "bv_k8";
export var caseStudy__bgDark = "bv_kT";
export var caseStudy__bgLight = "bv_kS";
export var caseStudy__bgLightReverse = "bv_lT";